html, body, #root{
  position: relative;
  height: 100%;
  min-height: 100%;
}
#root{
  display: flex;
  flex-direction: column;
}

.app-main-navbar{
  flex: 0 1 auto;
}

.app-body{
  flex: 1 1 auto;
  min-height: 0;
  display: flex;
  justify-content: center;
}

.category-title{
  font-size: 1.3rem;
}

.cursor-pointer {
  cursor: pointer;
}

.loader-wrapper{
  align-self: center;
}

.list-wrapper{
  width: 600px;
  min-width: 0;
  max-width: 100%;
}

.filters-wrapper{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.product-row{
  padding-bottom: 4px;
}

.product-row + .product-row{
  margin-top: 8px;
}

.padded-h-1rem + .padded-h-1rem{
  padding-left: 1rem;
}

.padded-v-1rem + .padded-v-1rem{
  padding-top: 1rem;
}


/*Priority*/
.product-row.priority-0{
  background-color: #E3EBFF;
}

.product-row.priority-1{
  background-color: #FEE2DC;
}

.product-row.priority-2{
  background-color: #fff3d8;
}

.product-row.priority-3{
  background-color: #D8D8D8;
}

.product-row.priority-4{
  background-color: #E8FFDC;
}

.priority-text-0{
  color: #013b6e;
}

.priority-text-1{
  color: #A71003;
}

.priority-text-2{
  color: #a99801;
}

.priority-text-3{
  color: #050505;
}

.priority-text-4{
  color: #02fa02;
}

.priority-input{
  width: 15px;
  height: 15px;
}

.priority-input:checked:after{
  content: '';
  display: block;
  border-radius: 2px;
  width: 16px;
  height: 16px;
}

.priority-input.priority-0:checked:after{
  background-color: #013b6e;
}

.priority-input.priority-1:checked:after{
  background-color: #a71003;
}

.priority-input.priority-2:checked:after{
  background-color: #a99801;
}

.priority-input.priority-3:checked:after{
  background-color: #050505;
}

.priority-input.priority-4:checked:after{
  background-color: #02fa02;
}

.image-popup{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #05050555;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.image-popup--image{
  position: relative;
  border-radius: 4px;
}

.summary {
  background-color: lemonchiffon;
  font-size: 2rem;
}